import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import KeyIcon from '@mui/icons-material/Key';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import RegistrationAgreement from '../components/RegistrationAgreement'
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; 


function Registration() {

  const registerbg = document.getElementById('registration');

  registerbg.classList.add('available');


  const login = () => {
    window.location.replace("/login");
  };



  // const validationSchema = Yup.object().shape({
  //   username: Yup.string().min(3).max(15).required(),
  //   password: Yup.string().min(4).max(20).required(),
  //   Firstname: Yup.string().min(3).max(15).required(),
  //   Lastname: Yup.string().min(4).max(20).required(),
  //   Email: Yup.string().min(3).max(30).required(),
  //   Phonenumber: Yup.string().min(4).max(30).required(),
  //   Notification: Yup.string().min(3).max(30).required(),
  //   Agency: Yup.string().min(3).max(30).required(),
  //   Agency_zipcode: Yup.string().min(5).max(5).required(),
  //   nearest_location: Yup.string().min(4).max(30).required(),
  // });

  const [referrer_fname, setReferrerFirst] = useState('');
  const [referrer_lname, setReferrerLast] = useState('');
  const [referrer_email, setReferrerEmail] = useState('');
  const [referrer_phone, setReferrerPhone] = useState('');
  const [referrer_agency, setReferrerAgency] = useState('');
  const [referrer_agencyzip, setReferrerAgencyZip] = useState('');
  const [referrer_agency_id, setReferrerAgencyId] = useState('');
  const [phone_provider, setPhoneProvider] = useState('');
  const [locations, setAddresses] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [userError, setUserError] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [bothError, setBothError] = React.useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Test Message')
  const [alertSeverity, setAlertSeverity] = useState('info')

  //agreements
  const [termsAgreement, setTermsAgreement] = React.useState(false);

  const handleSetTermsAgreement = (event) => {
    setTermsAgreement(event.target.checked);
  };

  useEffect(() => {
    const getAddresses = () => {
      axios.get(`${process.env.REACT_APP_API}locations/all-locations`).then((response) => {
        console.log(response.data);
        setAddresses(response.data);

      }).catch(error => console.error('Error:' + error));
    };

    const get_agencies = () => {
      axios.get(`${process.env.REACT_APP_API}agency/all-agencies`).then((response) => {
        // console.log(response.data);
        setAgencies(response.data);
      }).catch(error => console.error('Error:' + error));
      
      setAgencies(agencies)
    }
    get_agencies()
    getAddresses();
  }, []);
  var V = null;
  console.log(V)
  const handleSetEmail = (event) => {
    setReferrerEmail(event.target.value)
  };

  const handleSetUsername = (event) => {
    setUsername(event.target.value);
  };

  // const handleEmailKeyPress = (e) => {
  //   var key = e.key;
  //   if (key == "Backspace") {
  //     setEmailError("")
  // }
  // console.log(key + " was pressed")
  // }

  // const handleUsernameKeyPress = (e) => {
  //   var key = e.key;
  //   if (key == "Backspace") {
  //     setEmailError("")
  // }
  // }

  
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setAlertOpen(false);
    };

  const handleSetAgency = (event) => {
    console.log(event);
    let agency_text = event.target.textContent.split('-')
    let agency_name = agency_text[0]
    let agency_zip = agency_text[1]
    let agency_id = event.target.title
    console.log(agency_id,agency_name, agency_zip)
    setReferrerAgency(agency_name)
    setReferrerAgencyZip(agency_zip)
    setReferrerAgencyId(agency_id)
    // setAgency(event)
  }
  const handleSetProvider = (event) => {
    setPhoneProvider(event.target.value);
  };

  const [nearest_location, setNearestLocation] = React.useState('');
  const handleSetNearestLocation = (event) => {
    setNearestLocation(event.target.value);
  };

  const [notification_preference, setNotificationPreference] = React.useState('');
  const handleSetNotificationPreference = (event) => {
    setNotificationPreference(event.target.value);
  };

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  async function handleWelcomeEmail() {

    try {
      await axios.post(`${process.env.REACT_APP_API}welcome-email`, {
        email: referrer_email,
        subject: "Nice, You've Successfully Registered!",
        //text: text,
        firstname: referrer_fname,
        username: username,
        password: password
      })
    } catch (error) {
      console.log(error)
    }
  }
  console.log(userError)
  const register = () => {

    if (notification_preference === "") {
      setNotificationPreference("Email");
    }
    const data = {
      username, password, first_name: referrer_fname, last_name: referrer_lname, email: referrer_email,
      phone_number: referrer_phone, phone_provider, notification: notification_preference, agency: referrer_agency, agency_zipcode: referrer_agencyzip,
      nearest_location: nearest_location, role: "Referrer", agency_id: referrer_agency_id
    }

    axios.post(`${process.env.REACT_APP_API}auth`, data).then((response) => {
      let err_state = email_validation(response.data.error)
      console.log(response)
      if (err_state === 0) {
        if (response.data.code === 0){
          console.log('success')
          setAlertMessage(response.data.message);
          setAlertSeverity('success')
          setAlertOpen(true);
          handleWelcomeEmail();
          window.location.replace("/Login");

        } else {
          console.log('failed')
          console.log(response.data.error)
          setAlertMessage(response.data.message);
            setAlertSeverity('error')
            setAlertOpen(true);
        }
      }
        
    });
  };

  const email_validation = (error_message) =>{
    let code = 0
    if (error_message === "Username already in use!") {
      setUserError(error_message);
      code = 1;
    } else if (error_message === "Email already in use!") {
      setEmailError(error_message);
      code = 1;
    } else if (error_message === "Both username and email are already in use!") {
      setBothError(error_message);
      code = 1;
    }

    setAlertMessage(error_message);
    setAlertSeverity('error')
    setAlertOpen(true);

    return code
  }

  // email validation
  var emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (referrer_email.match(emailFormat) || referrer_email === "") {
    var emailErrorState = false;
  } else {
    var emailErrorState = true;
  }

  // phone validation
  var phoneLength = /^\d{10}$/;
  if (referrer_phone.match(phoneLength) || referrer_phone === "") {
    var phoneErrorState = false;
  } else {
    var phoneErrorState = true;
  }


  //check for any errors to disable register button
  if (emailErrorState || phoneErrorState) {
    var disabled = true;
  }

  //if no errors, set disabled to false to enable register button
  if (!emailErrorState && !phoneErrorState) {
    var disabled = false;
  }

  //create boolean variable check to display message if any fields are empty
  if (referrer_phone === "" || referrer_email === "" || referrer_fname === "" || referrer_lname === ""
    || referrer_agency === "" || nearest_location === "" || username === "" || password === "") {
    var disabled = true;
    var emptiesExist = true;
  } else {
    var disabled = false;
    var emptiesExist = false;
  }



  return (

    <><>
      {/* <Box sx={{ width: "100%", textAlign: "center" }}>
       <img  src={loginBg} />
    </Box> */}
    </>
      <Container maxWidth="md">

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} variant="filled" severity={alertSeverity} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={0}
        >
          <Box
            className="registrationBox"
            sx={{
              height: 'auto',
              backgroundColor: '#fff',
              padding: 7,
            }}
          >
            <Typography>
              <Box sx={{ flex: '1 1 100%', fontSize: "h5.fontSize", paddingBottom: 1, color: "#86c341" }}
                variant="h5"
                fontWeight={600}
              >
                Registration
              </Box>

              <Box sx={{ flex: '1 1 100%', paddingBottom: 5 }}>
                Create an account to submit and manage your student referrals
              </Box>

            </Typography>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={0}
              className="firstLastStack"
            >
              <TextField
                label="First Name"
                id="firstname"
                value={referrer_fname}
                sx={{ m: 1, width: '50%' }}
                type="text"
                onChange={(event) => {
                  setReferrerFirst(event.target.value);
                }}
              />
              <TextField
                label="Last Name"
                id="lastname"
                value={referrer_lname}
                sx={{ m: 1, width: '50%' }}
                type="text"
                onChange={(event) => {
                  setReferrerLast(event.target.value);
                }}
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={0}
            >

              <TextField
                label="Phone Number"
                id="phone"
                value={referrer_phone}
                sx={{ m: 1, width: '30%' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><PhoneIcon sx={{ p: '10px', color: '#858585' }} />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /></InputAdornment>,
                }}
                type="text"
                onChange={(event) => {
                  setReferrerPhone(event.target.value);
                }} />
              <TextField
                label="Email"
                id="email"
                value={referrer_email}
                sx={{ m: 1, width: '70%' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><AlternateEmailIcon sx={{ p: '10px', color: '#858585' }} />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /></InputAdornment>,
                }}
                type="text"
                onChange={handleSetEmail}
                onKeyUp={(e) => {
                  if (e.key === "Backspace" || e.key != "Enter") {
                    setEmailError("");
                    setBothError("");
                  }
                }}
              />
            </Stack>
            {(() => {
              if (phoneErrorState && emailErrorState) {
                return <FormHelperText style={{ color: "red", marginLeft: "10px", marginBottom: "10px" }}>Invalid phone number and email address</FormHelperText>
              } else if (emailErrorState) {
                return <FormHelperText style={{ color: "red", marginLeft: "10px", marginBottom: "10px" }}>Invalid email address</FormHelperText>
              } else if (phoneErrorState) {
                return <FormHelperText style={{ color: "red", marginLeft: "10px", marginBottom: "10px" }}>Invalid phone number</FormHelperText>
              } else if (emailError === "Email already in use!") {
                return <FormHelperText style={{ color: "red", marginLeft: "10px", marginBottom: "10px" }}>This Email is already in use!</FormHelperText>
              } else {
                return <></>
              }
            })()}

            {/* // Test Autocomplete */}
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={0}
            >
              <FormControl sx={{ m: 1, width: "100%" }}>
                 <Autocomplete
                //  label='Agency / School'
                  disablePortal
                  id="combo-box-demo"
                  // options={agencies.map((agencies) => (<MenuItem key={agencies.id} value={agencies.agency_name}/>))}
                  options={agencies}
                  getOptionLabel={(option) => option.agency_name}
                  renderOption={(props, option) => (
                    <li {...props} 
                      key={option.agency_id}
                      data-id={option.agency_id} 
                      title={option.agency_id}>
                      
                      {option.agency_name} - {option.agency_zip}
                    </li>
                  )}
                  
                  onChange={handleSetAgency}
                  selectOnFocus
                  renderInput={(params) => <TextField {...params} label="Agency / School" 
              
                  />}
                />
                </FormControl>

            </Stack>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={0}
            >

              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="nearest-location-label">Nearest Giving Closet Project Location</InputLabel>
                <Select
                  labelId="nearest-location-label"
                  id="nearest-location"
                  value={nearest_location}
                  label="Nearest Giving Closet Project Location"
                  fullWidth
                  onChange={handleSetNearestLocation}
                  MenuProps={{ disableScrollLock: true }}
                >
                  {locations.map((locations) => (
                    <MenuItem key={locations.index} value={locations.name + ", " + locations.address + ", " + locations.county}>{locations.name} - {locations.address}, {locations.county}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>This will be your care package pickup location.</FormHelperText>
              </FormControl>
            </Stack>

            {/* <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={0}
            > */}

              {/* {(() => {
                if (phone_provider === 'Non-Cellular') {
                  return <FormControl sx={{ m: 1, width: "50%" }}>
                    <InputLabel id="preferred-contact-label">Preferred Contact</InputLabel>
                    <Select
                      labelId="preferred-contact-label"
                      id="preferred-contact"
                      value="Email"
                      defaultValue=""
                      label="Preferred Contact"
                      disabled
                      fullWidth
                      onChange={handleSetNotificationPreference}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      <MenuItem value='Email'>Email</MenuItem>
                      <MenuItem value='Phone'>Phone</MenuItem>
                      <MenuItem value='Both'>Both</MenuItem>
                    </Select>
                  </FormControl>;
                } else {
                  return <FormControl sx={{ m: 1, width: "50%" }}>
                    <InputLabel id="preferred-contact-label">Preferred Contact</InputLabel>
                    <Select
                      labelId="preferred-contact-label"
                      id="preferred-contact"
                      value={notification_preference}
                      label="Preferred Contact"
                      fullWidth
                      onChange={handleSetNotificationPreference}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      <MenuItem value='Email'>Email</MenuItem>
                      <MenuItem value='Phone'>Phone</MenuItem>
                      <MenuItem value='Both'>Both</MenuItem>
                    </Select>
                  </FormControl>;
                }
              })()}

              <FormControl sx={{ m: 1, width: "50%" }}>
                <InputLabel id="phone-provider-label">Phone Provider</InputLabel>
                <Select
                  labelId="phone-provider-label"
                  id="phone-provider"
                  value={phone_provider}
                  label="Phone Provider"
                  fullWidth
                  onChange={handleSetProvider}
                  MenuProps={{ disableScrollLock: true }}
                >
                  <MenuItem value='Non-Cellular'>Non-Cellular/Landline</MenuItem>
                  <MenuItem value='@txt.att.net'>AT&T</MenuItem>
                  <MenuItem value='@comcastpcs.textmsg.com'>Comcast</MenuItem>
                  <MenuItem value='@mms.cricketwireless.net'>Cricket Wireless</MenuItem>
                  <MenuItem value='@msg.fi.google.com'>Google Project Fi</MenuItem>
                  <MenuItem value='@mymetropcs.com'>Metro PCS</MenuItem>
                  <MenuItem value='@sprintpaging.com'>Sprint</MenuItem>
                  <MenuItem value='@tmomail.net'>T-Mobile</MenuItem>
                  <MenuItem value='@vtext.com'>Verizon</MenuItem>
                </Select>
                <FormHelperText>We'll need this if you'd like text updates. (Optional)</FormHelperText>
              </FormControl>
            </Stack> */}

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={0}
            >

              <TextField
                label="Username"
                id="username"
                value={username}
                sx={{ m: 1, width: '50%' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><PersonIcon sx={{ p: '10px', color: '#858585' }} />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /></InputAdornment>,
                }}
                type="text"
                onChange={handleSetUsername}
                onKeyUp={(e) => {
                  if (e.key === "Backspace" || e.key != "Enter") {
                    setUserError("");
                    setBothError("");
                  }
                }}
              />

              <TextField
                label="Password"
                id="password"
                value={password}
                sx={{ m: 1, width: '50%' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><KeyIcon sx={{ p: '10px', color: '#858585' }} />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /></InputAdornment>,
                }}
                type="password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }} />
            </Stack>
            {(() => {
              if (bothError === "Both username and email are already in use!") {
                return <FormHelperText style={{ color: "red", marginLeft: "10px", marginBottom: "10px" }}>Both username and email are already in use!</FormHelperText>
              }
              else if (userError === "Username already in use!") {
                return <FormHelperText style={{ color: "red", marginLeft: "10px", marginBottom: "10px" }}>This Username is already in use!</FormHelperText>
              } else {
                return <></>
              }
            })()}
            {(() => {
              if (emptiesExist) {
                return <FormHelperText style={{ color: "red", marginLeft: "10px", marginBottom: "10px" }}>Please fill out all fields!</FormHelperText>
              } else {
                return <></>
              }
            })()}

            <Stack spacing={3} direction={{ xs: 'column', md: 'row' }}>
              <Box sx={{ fontStyle: 'italic', paddingTop: 5, paddingBottom: 7, display: "inline" }}>
                <RegistrationAgreement />
                <FormControlLabel
                  control={<Checkbox checked={termsAgreement} onChange={handleSetTermsAgreement} />} label="I have read and agree to the above service terms." /></Box>

            </Stack>

            {(() => {

              if (termsAgreement) {
                return <Button disabled={disabled} color="success" size="large" variant="contained" onClick={register}> Register </Button>;
              } else {
                return <Button disabled color="error" size="large" variant="contained"> Register </Button>;
              }
            })()}


            <Button color="success" sx={{ backgroundColor: "#86c341" }} size="large" variant="contained" onClick={login}> Return to Login </Button>



          </Box>
        </Stack>
      </Container></>
  );
}

export default Registration;
