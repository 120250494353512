import React from "react";
import Box from "@mui/material/Box";
import { Button, Modal} from "@mui/material";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@material-ui/core/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import logo from "../images/email-logo.png";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderitems: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});



function ResetPasswordRequest() {
  const [email, setEmail] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Test Message')
  const [alertSeverity, setAlertSeverity] = useState('info')
  const [requestCode, setRequestCode] = useState('')
  const [requestError, setRequestError] = useState('')
  

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const callPasswordReset = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}pwreset/request-reset/`, { email });
      if (response.data.error) {
        setAlertMessage(response.data.error);
        setAlertSeverity('error');
        setAlertOpen(true);
        console.log('error');
        return { code: 1, message: response.data.error };
      } else {
        setAlertMessage('Check your email for a message from info@givingclosetproject.org');
        setAlertSeverity('success');
        setAlertOpen(true);
        return { code: 0, message: 'Send Email' };
      }
    } catch (error) {
      // Handle any unexpected errors
      setAlertMessage('An unexpected error occurred. Please try again later.');
      setAlertSeverity('error');
      setAlertOpen(true);
      console.error('Unexpected error:', error);
      return { code: 1, message: 'Unexpected error' };
    }

  //   axios.post(`${process.env.REACT_APP_API}pwreset/request-reset/`, {email: email}).then((response) => {
  //     if (response.data.error) {
  //       setAlertMessage(response.data.error)
  //       setAlertSeverity('error')
  //       setAlertOpen(true)
  //       console.log('error')
  //       // setRequestError()
  //       // setRequestCode(response.data.code)
  //       return {'code': 1, 'message': response.data.error}
  //     } else {

  //       // setRequestCode(0)
  //       setAlertMessage('Check your email for a message from info@givingclosetproject.org')
  //       setAlertSeverity('success')
  //       setAlertOpen(true)
  //       return {'code': 0, 'message': 'Send Email'}

  //     }

  // })
  
};


async function handleResetEmail() {

  try {
    await axios.post(`${process.env.REACT_APP_API}reset-password-email`, {
      email: email,
      subject: "Action Required: Reset Your Giving Closet Password",
      baseUrl: `${process.env.REACT_APP_WEB}`
    })
  } catch (error) {
    console.log(error)
  }
}
  const sendPasswordResetRequest  = async () => {
    
    if (validateEmail(email)) {

      const pwSuccess = await callPasswordReset()
      console.log(pwSuccess)
      if (pwSuccess.code === 0) {
          console.log('Send Email')
          handleResetEmail()
      }

      console.log(pwSuccess)
      if (pwSuccess.code == 0) {
        let msg =  `If you don't receive the email within a few minutes, please check your spam folder or try again.\n
        For your security, the reset link will expire in 1 hour.`
        setAlertMessage(msg)
        setAlertSeverity('success')
        setAlertOpen(true)

      } else {
        setAlertMessage(pwSuccess.msg)
        setAlertSeverity('error')
        setAlertOpen(true)
      }
      

    } else {
      let msg = 'Please enter a valid email address.'
      setAlertMessage(msg)
      setAlertSeverity('error')
      setAlertOpen(true)
    }
    
  };
  
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setAlertOpen(false);
    };
  const handleSetEmail =(event) => {
    console.log(event);
    setEmail(event.target.value);
  }

  const history = useHistory();


  const handleImgClick = () => {
    history.push("/login")
  };
  
  
  return (
    <div>

<Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} variant="filled" severity={alertSeverity} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <a onClick={handleImgClick}><img src={logo} alt="GCP Logo" /></a> */}
        <Box
         component="img"
         sx={{cursor: "pointer"}}
         src={logo}
         onClick={handleImgClick}/>
      </div>
      <Typography
        variant="h5"
        fontWeight={400}
        style={{ padding: "50px", textAlign: "center" }}
      >
        Reset Your Password
      </Typography>
      
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      <Stack spacing={1} direction="column" sx={{ width: "33%" }}>
        
      <Typography
        variant="p"
        fontWeight={400}
        style={{ padding: "40px", textAlign: "center" }}
      >
        Enter your email address below 
        and we'll send you a link to reset your password.
      </Typography>

        <FormControl sx={{ width: "100%", paddingBottom: "25px" }}>
            <CssTextField
              label="Enter Email"
              variant="outlined"
              defaultValue=""
              type="email"
              value={email}
              onChange={handleSetEmail}
              required
              style={{ width: "100%", paddingBottom: "25px" }}
            />
            <Button color="success" fullWidth={true} size="large" variant="contained" onClick={sendPasswordResetRequest}> Send Email </Button>

            </FormControl>



            </Stack>
{/* 
           <Item>
            </Item> */}
        </div>
    </div>
  );
}

export default ResetPasswordRequest;
