import React from "react";
import Box from "@mui/material/Box";
import { Button, Modal, Container, Grid} from "@mui/material";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@material-ui/core/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
// import FormControl from "@mui/material/FormControl";

import logo from "../images/email-logo.png";

const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderitems: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  });

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Test Message')
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [resetToken, setResetToken] = useState('');
    const [userId, setUserId] = useState('');

    const urlParams = new URLSearchParams(window.location.search);


    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
        };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(resetToken)
        console.log(userId);
    
        if (password !== confirmPassword) {
          setError('Passwords do not match');
          setAlertSeverity('error')
          setAlertMessage('Passwords do not match')
          setAlertOpen(true);
          
          return;
        }
    
        if (password.length < 8) {
          setAlertMessage('Password must be at least 8 characters long');
          setError('Password must be at least 8 characters long');
          setAlertSeverity('error')
          setAlertOpen('true')
          return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API}pwreset/reset-password`, { 
                password: password,  
                userId: userId,
                resetToken:resetToken,
             });

            if (response.data.code == 0){
                setSuccess('Your password has been reset successfully. You will be redirected to the login page.');
              setAlertSeverity('success')
              setAlertMessage('Your password has been reset successfully. You will be redirected to the login page.')
              setAlertOpen(true);
              setTimeout(() => {
                window.location.href = '/login';
              }, 3000);

            } else {
                setAlertSeverity('error')
                setAlertMessage(response.data.message)
                setAlertOpen(true);
            } 
          } catch (err) {
            setError('An error occurred. Please try again.');
          }
    };

    const history = useHistory();


    const handleImgClick = () => {
      history.push("/login")
    };

    useEffect(() => {
        setResetToken(urlParams.get('tk'));
        setUserId(urlParams.get('id'));

    })

    return (
        <div>

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} variant="filled" severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
                <Box
         component="img"
         sx={{cursor: "pointer"}}
         src={logo}
         onClick={handleImgClick}/>
      
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Reset Your Password
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please enter your new password below. Your password should be at least 8 characters long and include a mix of letters, numbers, and special characters for enhanced security.
          </Typography>
          <form onSubmit={handleSubmit}>
          <FormControl sx={{ width: "100%", paddingBottom: "25px" }}>
            <CssTextField
              label="New Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            </FormControl>
            <FormControl sx={{ width: "100%", paddingBottom: "25px" }}>
            <TextField
              label="Confirm New Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            </FormControl>
            {error && (
              <Typography color="error" variant="body2" gutterBottom>
                {error}
              </Typography>
            )}
            {success && (
              <Typography color="primary" variant="body2" gutterBottom>
                {success}
              </Typography>
            )}
            <Button type="submit" variant="contained" color="success" fullWidth>
              Reset Password
            </Button>
          </form>
        </Box>
      </Grid>
    </Container>
        </div>
    )
}

export default ResetPassword;